body { background-color: #e9eaef; }

.container {
    display: flex; 
    flex-direction: column; 
    justify-content: flex-start; 
    height: 100vh;
    background-color: #017d7d;
}

.top-banner-section {
    background-color: #000000; 
}

.top-section {
    padding: 30px 10px;
    background-color: #f5b901; 
    text-align: center; 
    border-bottom: 3px solid #b8952f; 
}

.top-section h1 {
    color: #000;
    font: bold 40px/1 "Poppins", sans-serif;
}

.top-section .description {
    color: #ffffff; 
    font: 300 16px/1.5 "Poppins", sans-serif;
    max-width: 800px; 
    margin: 0 auto; 
    padding: 0 20px; 
}

.whyChooseUsSection {
    background-color: #f5b901; 
    color: #000; 
}

.whyChooseUsHeader {
    color: #017d7d; 
}

.feature strong {
    color: #b8952f; 
}

@media screen and (max-width: 600px) {
    /* .top-section h1 { font-size: 1.5em; } */
    .description { text-align: justify; }  
    
    
    .top-banner-section img {
        width: 100%; /* Ensures the banner image fits the screen width */
        height: auto; /* Maintains aspect ratio */
    }

    .top-section h1 {
        font-size: 24px; /* Smaller font size for headers */
    }

    .top-section .description {
        font-size: 14px; /* Smaller font size for descriptions */
        padding: 0 10px; /* Less padding for text blocks */
    }

    .whyChooseUsSection, .joinOurCommunitySection, .royalRoadSection {
        padding: 20px 10px; /* Uniform padding for sections */
    }


    .feature {
        padding: 8px; /* Smaller padding for feature boxes */
        margin: 5px 0; /* Less margin to save space */
    }

    .feature:hover {
        transform: none; /* Disable hover effects that might not work well on touch devices */
    }    
}



.royalRoadSection {
    padding: 20px;
    background-color: #017d7d; 
    text-align: center;
    margin: 20px 0; 
}

.royalRoadText {
    color: #f5b901; 
    font-size: 20px; 
    font-weight: bold; 
}

.feature {
    padding: 10px;
    margin: 10px 0;
    border-left: 5px solid #b8952f;
    background-color: #fff8e1;
    color: #333;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}
.feature:hover {
    transform: translateY(-5px);
}
.feature strong {
    display: inline-block;
    margin-right: 10px;
    color: #b8952f;
    font-weight: bold;
}
.whyChooseUsHeader {
    text-transform: uppercase;
    letter-spacing: 2px;
}
