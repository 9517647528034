
@media screen and (max-width: 600px) {
    .joinButton {
        font-size: 16px; 
        padding: 12px 24px;
    }


}

.joinOurCommunitySection {
    padding: 30px 20px;
    background-color: #000; 
    text-align: center;
    border-top: 3px solid #f5b901; 
    margin-top: 30px;
}

.communityHeader {
    color: #f5b901; 
    font-size: 24px; 
    margin-bottom: 15px; 
}

.communityDescription {
    color: #fff; 
    font-size: 16px;
    margin-bottom: 20px; 
}

.joinButton {
    font-size: 18px;
    color: #000; 
    background-color: #b8952f; 
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s; 
}

.joinButton:hover {
    background-color: #f5b901; 
}


.modalHeader {
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .inputField {
    width: calc(100% - 16px); 
    padding: 8px;
    margin-top: 5px;
    border: 2px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  .inputField:focus {
    border-color: #6658d3; 
  }
  
  .signupButton {
    width: 100%;
    padding: 10px 0;
    margin-top: 15px;
    background-color: #047979;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .signupButton:hover {
    background-color: #0a9b9b;
  }
  
  .closeButton {
    cursor: pointer;
    float: right;
    font-size: 25px;
    color: #aaa;
  }
  
  .closeButton:hover {
    color: #f00;
  }
  
  
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); 
    animation: fadeIn 0.5s;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .modalContent {
    background-color: #fff;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px; 
    animation: slideIn 0.3s;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .signupButton:disabled {
    background-color: #cccccc; 
    cursor: not-allowed; 
  }


.spinner {
  margin-right: 10px;
  border: 4px solid rgba(0, 0, 0, 0.1); 
  border-top: 4px solid #666;
  border-radius: 50%; 
  width: 20px; 
  height: 20px; 
  animation: spin 1s linear infinite; 
}

@keyframes spin {
    0% {
        transform: rotate(0deg); 
    }
    100% {
        transform: rotate(360deg);
    }
} 

.pointerArrow {
  font-size: 24px; 
  margin-right: 10px; 
  animation: blink 1s infinite; 
}

@keyframes blink {
  0%, 50%, 100% {
      opacity: 1;
  }
  25%, 75% {
      opacity: 0;
  }
}

.floatingButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 18px;
  color: #fff; 
  background-color: #009688;
  border: none;
  padding: 15px 25px;
  border-radius: 50px; 
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; 
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.floatingButton:hover {
  background-color: #00796b; 
  transform: scale(1.05); 
}
